<script lang="ts">
  import { onMount } from "svelte";
  import { quintOut } from "svelte/easing";
  import { crossfade, fade, scale } from "svelte/transition";

  const [send, receive] = crossfade({
    duration: (d) => Math.sqrt(d * 200),
    fallback(node, _params) {
      const style = getComputedStyle(node);
      const transform = style.transform === "none" ? "" : style.transform;
      return {
        duration: 600,
        easing: quintOut,
        css: (t) => `
				transform: ${transform} scale(${t});
				opacity: ${t}
			`,
      };
    },
  });

  const BREAKPOINT = 800;

  // svelte-ignore non_reactive_update
  let container: HTMLElement;
  let removalTimeOut: number = -1;
  let expanded: boolean = $state(false);
  let expandedCompact: boolean = $state(false);
  let pending: boolean = $state(false);

  onMount(() => {
    window.document.addEventListener(
      "astro:before-preparation",
      handleBeforePreparation,
    );
    window.document.addEventListener("astro:after-swap", handleAfterSwap);
    if (window.innerWidth > BREAKPOINT) {
      expanded = true;
      removalTimeOut = window.setTimeout(() => {
        expanded = false;
      }, 3000);
    }
  });

  function handleMenuClick() {
    if (window.innerWidth >= BREAKPOINT) return;
    expandedCompact = true;
  }

  function handleWrapperClick() {
    expandedCompact = false;
  }

  function handlePointerEnter(e: PointerEvent) {
    if (window.innerWidth < BREAKPOINT) return;
    window.clearTimeout(removalTimeOut);
    expanded = true;
    container?.setPointerCapture(e.pointerId);
  }

  function handlePointerLeave(e: PointerEvent) {
    if (window.innerWidth < BREAKPOINT) return;
    container?.releasePointerCapture(e.pointerId);
    removalTimeOut = window.setTimeout(() => {
      expanded = false;
    }, 1000);
  }

  function handleBeforePreparation() {
    pending = true;
  }

  function handleAfterSwap() {
    pending = false;
  }
</script>

{#if expandedCompact}
  <div
    aria-hidden="true"
    transition:fade={{ duration: 100 }}
    class="backdrop"
  ></div>
  <!-- svelte-ignore a11y_click_events_have_key_events -->
  <!-- svelte-ignore a11y_no_static_element_interactions -->
  <div class="compact-wrapper" onclick={handleWrapperClick}>
    <div class="extra-items before">
      <a
        transition:scale={{ duration: 200 }}
        target="_self"
        href="/trials"
        style="background: white; color: var(--orange); border-color: var(--orange)"
      >
        Trials
      </a>
    </div>
    <nav class="caterpillar-container-compact-expanded">
      <a
        in:receive={{ key: 0 }}
        out:send={{ key: 0 }}
        target="_self"
        href="/typefaces">Typefaces</a
      >
      <a
        in:receive={{ key: 1 }}
        out:send={{ key: 1 }}
        target="_self"
        href="/custom">Custom</a
      >
      <a
        in:receive={{ key: 2 }}
        out:send={{ key: 2 }}
        target="_self"
        href="/projects">Projects</a
      >
      <a
        in:receive={{ key: 3 }}
        out:send={{ key: 3 }}
        target="_self"
        href="/info">Information</a
      >
    </nav>
    <div class="extra-items after">
      <a
        transition:fade={{ duration: 100 }}
        target="_self"
        href="/shop"
        style="background: white; color: var(--green); border-color: var(--green)"
      >
        Shop
      </a>
    </div>
  </div>
{:else}
  <!-- svelte-ignore a11y_no_noninteractive_element_to_interactive_role -->
  <!-- svelte-ignore a11y_click_events_have_key_events -->
  <nav
    role="button"
    bind:this={container}
    class="caterpillar-container"
    onclick={handleMenuClick}
    onpointerenter={handlePointerEnter}
    onpointerleave={handlePointerLeave}
    class:expanded
    class:pending
  >
    <a
      in:receive={{ key: 0 }}
      out:send={{ key: 0 }}
      target="_self"
      href="/typefaces">Typefaces</a
    >
    <a
      in:receive={{ key: 1 }}
      out:send={{ key: 1 }}
      target="_self"
      href="/custom">Custom</a
    >
    <a in:receive={{ key: 2 }} out:send={{ key: 2 }} target="_self" href="/projects"
      >Projects</a
    >
    <a
      in:receive={{ key: 3 }}
      out:send={{ key: 3 }}
      target="_self"
      href="/info">Information</a
    >
  </nav>
{/if}

<style>
  /* Standard Container (Desktop, Mobile Collapsed) */
  nav.caterpillar-container {
    cursor: pointer;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    --itemOffset: -18px;

    &.pending {
      a {
        animation-name: jump;
        animation-duration: 400ms;
        animation-iteration-count: infinite;
        animation-timing-function: ease-in-out;
        animation-fill-mode: forwards;
      }
    }

    a {
      width: var(--button-height);
      animation: jump 400ms 1 ease-in-out forwards;

      @media (max-width: 800px) {
        pointer-events: none;
      }
    }

    /* TODO No-js fallback, add &:hover, here if noscript */
    &.expanded {
      a {
        border-radius: 10px;
        transform: translateX(0);
        opacity: 1;
        margin-left: 0;

        &:nth-of-type(1) {
          width: calc(5.875 * var(--font-size));
          color: #ffffff;
        }
        &:nth-of-type(2) {
          width: calc(4.75 * var(--font-size));
          color: #ffffff;
        }
        &:nth-of-type(3) {
          width: calc(5.0625 * var(--font-size));
          color: #000000;
        }
        &:nth-of-type(4) {
          width: calc(6.5 * var(--font-size));
          color: #000000;
        }
      }
    }
  }

  /* Compact Expanded Container (Mobile Expanded) */
  .backdrop,
  .compact-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    height: 100dvh;
    flex-direction: column;
  }
  .backdrop {
    z-index: 900;
    image-rendering: pixelated;
    image-rendering: -moz-crisp-edges;
    image-rendering: crisp-edges;
    -ms-interpolation-mode: nearest-neighbor;
    background-repeat: repeat;
    /* Background image 2x2 pixels 2 white 2 transparent  */
    background-image: url("data:image/webp;base64,UklGRkQAAABXRUJQVlA4WAoAAAAQAAAAAQAAAQAAQUxQSAUAAAAAAP//AABWUDggGAAAADABAJ0BKgIAAgACQDglpAADcAD++yGAAA==");
    background-size: 2px 2px;
  }
  .compact-wrapper {
    z-index: 1000;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  nav.caterpillar-container-compact-expanded {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: var(--main-margin);
    a {
      border-radius: 10px;
      font-size: calc(var(--font-size) * 2);

      &:nth-of-type(1) {
        color: #ffffff;
      }
      &:nth-of-type(2) {
        color: #ffffff;
      }
      &:nth-of-type(3) {
        color: #000000;
      }
      &:nth-of-type(4) {
        color: #000000;
      }
    }
  }

  .extra-items {
    &.after {
      padding-top: var(--main-margin);
    }

    &.before {
      padding-bottom: var(--main-margin);
    }

    a {
      display: block;
      border-style: solid;
      border-width: calc(var(--line-width) * 2);
      width: 4em;
      height: 4em;
      border-radius: 10em;
      font-size: calc(var(--font-size) * 2);
      color: #000000;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  /* General Button styles */
  nav {
    a {
      display: block;
      padding: var(--button-padding-y) var(--button-padding-x);
      text-decoration: none;
      border-radius: var(--button-height);
      line-height: var(--line-height);
      height: var(--button-height);
      transition: all 0.3s ease;
      overflow: hidden;
      user-select: none;

      &:nth-of-type(1) {
        background-color: #000000;
        color: #000000;
      }
      &:nth-of-type(2) {
        background: var(--greyDark);
        color: var(--greyDark);
        margin-left: var(--itemOffset);
        animation-delay: 100ms;
      }
      &:nth-of-type(3) {
        background: var(--greyMid);
        color: var(--greyMid);
        margin-left: var(--itemOffset);
        animation-delay: 200ms;
      }
      &:nth-of-type(4) {
        background: var(--greyLight);
        color: var(--greyLight);
        margin-left: var(--itemOffset);
        animation-delay: 300ms;
      }
    }
  }

  @keyframes jump {
    0% {
      transform: translate(0, 0);
    }
    50% {
      transform: translate(0, -5px);
    }
    100% {
      transform: translateY(0);
    }
  }
</style>
